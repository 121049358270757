import styled from 'styled-components';

export const Body = styled.div`
  background: #f8f8fc;
  border-radius: 37px 37px 0 0;
  display: flex;
  flex: 1;
  flex-direction: column;
  padding: 0 32px;
  width: 100%;
`;
